import { Vue } from 'vue-property-decorator';
import router from './router';
import { authStore } from '@/stores';
import _ from 'lodash';
import './mixins';
import 'element-ui/lib/theme-chalk/index.css';
import './components';
import './filters';
import 'vditor/src/assets/scss/index.scss';
import 'vditor/dist/css/content-theme/ant-design.css';

import './styles/global.scss';

Vue.prototype.$get = _.get;

Vue.prototype.$authStore = Vue.observable(authStore);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV !== 'production') {
  const VConsole = require('vconsole');
  if (/Android|WindowsPhone|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent)) {
    new VConsole;
  }
}

new Vue({
  router,
  data: {
    windowWidth: window.innerWidth
  },
  render: h => h(require('./app').default)
}).$mount('#app');
