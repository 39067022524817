import Vue from 'vue';
import './element';
Vue.use(require('./title').default);
Vue.component('app-search-input', require('./search-input').default);
Vue.component('app-header', require('./header').default);
Vue.component('app-footer', require('./footer').default);
Vue.component('app-popup', require('./popup').default);
Vue.component('app-side-menu', require('./side-menu').default);
Vue.component('app-post-card', require('./post-card').default);
Vue.component('app-post-list', require('./post-list').default);
Vue.component('post-info-form', require('./post-info-form').default);
Vue.component('markdown-editor', require('./markdown-editor').default);
