<template>
  <div class="post-list-wrapper">
    <el-row :gutter="15" v-if="false">
      <el-col :xs="24" :sm="8" v-for="item in store.data" :key="item.id">
        <app-post-card :post="item"/>
      </el-col>
    </el-row>
    <div v-else>
      <app-post-card
        v-for="item in store.data"
        :key="item.id"
        :post="item"
        type="item"
      />
    </div>
    <div class="empty-wrapper" v-if="store.isEmpty">
      <i class="icon el-icon-folder-opened"></i>
      <div>暂无<span v-if="$route.query.keyword"> {{$route.query.keyword}} </span>相关文章</div>
    </div>
    <div class="text-center" style="padding: 1.66667rem 0;">
      <el-pagination
        layout="prev, pager, next"
        :page-count="pageCount"
        :current-page="store.meta.page"
        :page-size="store.meta.per_page"
        :total="store.meta.total"
        @next-click="handleFetchData"
        @prev-click="handleFetchData"
        @current-change="handleFetchData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { Collection } from '@/stores';

  @Component
  export default class PostList extends Vue {
    @Prop({ type: Collection, default: () => new Collection }) store

    get pageCount() {
      const { total, per_page } = this.store.meta;
      return Math.ceil(total / per_page);
    }

    handleFetchData(page) {
      this.$router.push({ query: { ...this.$route.query, page } });
    }
  }
</script>

<style lang="scss" scoped>
  .post-list-wrapper {
    padding: 20px 0;

    .empty-wrapper {
      padding: 40px 0;
      text-align: center;
      color: #999;

      .icon {
        margin-bottom: 20px;
        font-size: 80px;
      }
    }
  }
</style>
