import wx from 'weixin-js-sdk';
import { isIOS } from './phone';
import { ENTRY_URL } from '@/constants';
import { fly } from './fly';

const configs = {};

const jsApiList = [
  'updateAppMessageShareData',
  'updateTimelineShareData',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'previewImage',
];

export function configSdk(data) {
  return new Promise((resolve, reject) => {
    wx.config({
      ...data,
      jsApiList
    });
    wx.ready(() => resolve(wx));
    wx.error((err) => reject(err));
  });
}

export async function getSdk() {
  // IOS下 location.pathname 可能不会更新
  let url = window.location.href.split('#')[0];
  if (isIOS()) {
    url = window.sessionStorage.getItem(ENTRY_URL);
  }
  if (configs[url]) {
    return isIOS() ? wx : configSdk(configs[url]);
  }
  const { data } = await fly.get('wechat/jssdk_config', { url });
  configs[url] = data;
  return configSdk(data);
}
