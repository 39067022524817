<template>
  <div>
    <v-title/>
    <app-header hidden-search>
      <el-button size="mini" @click="showResetPassword = true">修改密码</el-button>
    </app-header>
    <div class="container">
      <el-form ref="form" style="padding: 4.16667rem 0;" :model="form" label-width="5rem">
        <el-form-item
          label="头像"
          prop="avatar"
        >
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="handleUploadAvatar"
          >
            <img v-if="form.avatar" :src="form.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="昵称"
          prop="nickname"
          :rules="{ required: true, message: '昵称不能为空', trigger: 'blur' }"
        >
          <el-input placeholder="昵称" v-model="form.nickname"/>
        </el-form-item>
        <el-form-item
          label="网站名"
          prop="siteTitle"
          :rules="{ message: '网站名不能为空', trigger: 'blur' }"
        >
          <el-input placeholder="网站名" v-model="form.siteTitle"/>
        </el-form-item>
        <el-form-item
          label="slogan"
          prop="slogan"
        >
          <el-input placeholder="slogan" v-model="form.slogan"/>
        </el-form-item>
        <el-form-item
          label="邮箱"
          prop="email"
        >
          <el-input placeholder="邮箱" v-model="form.email"/>
        </el-form-item>
        <el-form-item
          label="github"
          prop="github"
        >
          <el-input placeholder="github" v-model="form.github"/>
        </el-form-item>
        <el-form-item
          label="crypto"
          prop="crypto"
        >
          <el-input placeholder="" type="textarea" v-model="form.crypto"/>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="introduction"
        >
          <div><markdown-editor @loaded="handleLoadedEditor" :value="form.introduction"/></div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="修改密码" :visible.sync="showResetPassword">
      <el-form ref="passwordForm" :model="passwordForm" label-width="6.66667rem">
        <el-form-item
          label="新密码"
          prop="password"
          :rules="{ required: true, message: '新密码不能为空', trigger: 'blur' }"
        >
          <el-input type="password" placeholder="新密码" v-model="passwordForm.password"/>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="confirmPassword"
          :rules="{ required: true, message: '确认密码不能为空', trigger: 'blur' }"
        >
          <el-input type="password" placeholder="确认密码" v-model="passwordForm.confirmPassword"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleResetPassword">确定</el-button>
          <el-button @click="showResetPassword = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/stores';
  import _ from 'lodash';
  import { uploadFile, autoLoading } from '@/utils';

  @Component
  export default class AuthorEdit extends Vue {
    showResetPassword = false
    passwordForm = {
      password: '',
      confirmPassword: '',
    }

    form = {
      nickname: '',
      siteTitle: '',
      avatar: '',
      slogan: '',
      introduction: '',
      email: '',
      github: '',
      crypto: '',
    }

    @Vue.autoLoading
    async created() {
      await authStore.tryFetchData();
      _.merge(this.form, _.pick(authStore.user, Object.keys(this.form)));
    }

    async handleUploadAvatar(e) {
      const url = await uploadFile(e.file);
      this.form.avatar = url;
    }

    @Vue.autoLoading
    async handleSubmit() {
      try {
        this.form.crypto && JSON.parse(this.form.crypto);
        this.form.introduction = this.$vditor.getValue();
        await authStore.update(this.form);
        this.$message('更新成功');
        this.$router.replace({ name: 'author' });
      } catch (e) {
        throw new Error(e);
      }
    }

    handleLoadedEditor(vditor) {
      this.$vditor = vditor;
    }

    handleResetPassword() {
      this.$refs.passwordForm.validate(async valid => {
        if (valid) {
          await autoLoading(this.$fly.put('users/password', this.passwordForm));
          this.$message('密码更新成功！');
          authStore.logout();
          this.$router.replace({ name: 'login' });
        }
      });
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .avatar-uploader {
    .el-upload {
      position: relative;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
    }

    .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      width: 100px;
      height: 100px;
      font-size: 28px;
      line-height: 100px;
      text-align: center;
      color: #8c939d;
    }

    .avatar {
      display: block;
      width: 100px;
      height: 100px;
    }
  }
</style>
