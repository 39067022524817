<template>
  <div class="page " style="flex: auto;">
    <v-title/>
    <app-header/>
    <div class="banner">
      <img :src="bannerBg" alt="" class="bg">
      <div class="content-wrapper">
        <router-link class="text-center" :to="{ name: 'author' }">
          <img :src="authorStore.avatar" class="avatar">
          <div class="title">{{ authorStore.nickname }}</div>
        </router-link>
        <div class="container one-text" v-if="one">
          <p>{{ one.text }}</p>
          <div style="text-align: right;" v-if="one.textAuthors">—— {{ one.textAuthors }}</div>
        </div>
      </div>
      <i class="el-icon-arrow-down" @click="handleScroll"></i>
    </div>
    <div class="container">
      <app-post-list :store="store"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { fly } from '@/utils';
  import { Collection, authStore, authorStore } from '@/stores';

  @Component
  export default class Pages extends Vue {
    store = Collection.create({
      fetch: params => fly.get('posts', params)
    })
    authorStore = authorStore
    one = ''

    bannerBg = ''

    @Vue.autoLoading
    async created() {
      this.getBannerBg();
      const { page = 1, keyword } = this.$route.query;
      const filter = {};
      keyword && (filter.title = { like: keyword });
      this.store.params.filter = filter;
      await Promise.all([
        this.fetchOneText(),
        this.store.fetchData({ page }),
        authStore.checkFetchData(),
        authorStore.tryFetchData(),
      ]);
    }

    getBannerBg() {
      let { innerHeight, innerWidth } = window;
      if (innerWidth < 750) {
        innerHeight *= 2;
        innerWidth *= 2;
      }
      this.bannerBg = `https://unsplash.it/${innerWidth}/${innerHeight}`;
    }

    async fetchOneText() {
      const { data } = await this.$fly.get('one/last');
      this.one = data;
    }

    handleScroll() {
      const cubic = value => Math.pow(value, 3);
      const easeInOutCubic = value => value < 0.5
        ? cubic(value * 2) / 2
        : 1 - cubic((1 - value) * 2) / 2;

      const el = document.documentElement;
      const beginTime = Date.now();
      const originScrollTop = el.scrollTop;
      const beginValue = window.innerHeight - originScrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = originScrollTop + (beginValue * easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = window.innerHeight;
        }
      };
      rAF(frameFunc);
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .header-component {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    border: none !important;

    .el-icon-s-unfold {
      color: #fff;
    }

    .input-wrapper {
      border-color: #fff;
      color: #fff;

      .input {
        color: #fff;

        &::placeholder {
          color: #e1e1e1;
        }
      }
    }
  }

  .banner {
    position: relative;
    height: 100vh;
    color: #fff;
    background: #000;
    background-size: cover;

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      object-fit: cover;
    }

    .el-icon-s-unfold {
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 30px;
      font-size: 26px;
      color: #fff;
      cursor: pointer;
    }

    .content-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.3);

      .avatar {
        width: 70px;
        height: 70px;
        margin-top: -100px;
        border: 4px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }

      .title {
        font-weight: bold;
        font-size: 50px;
      }

      .one-text {
        margin-top: 30px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.1em;
      }
    }

    .el-icon-arrow-down {
      position: absolute;
      z-index: 1;
      bottom: 50px;
      left: 50%;
      font-size: 50px;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
</style>
