<template>
  <div class="input-wrapper" :class="{ active: showInput }">
    <input v-show="showInput" placeholder="Search" ref="input" @blur="handleBlur" @keyup.enter="handleSearch" v-model="keyword" class="input" type="text">
    <i class="el-icon-search" v-show="!showInput" @click="handleShow"></i>
    <i class="el-icon-search" v-show="showInput" @click="handleSearch"></i>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { sleep } from '@/utils';

  @Component
  export default class SearchInput extends Vue {
    showInput = false
    keyword = ''

    async handleShow() {
      this.showInput = true;
      await this.$nextTick();
      this.$refs.input.focus();
    }

    async handleBlur() {
      await sleep(100);
      this.showInput = false;
    }

    handleSearch() {
      this.$router.push({
        name: 'post.list',
        query: { keyword: this.keyword }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .input-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 34PX;
    max-width: 100%;
    height: 34PX;
    border: 1px solid #999;
    border-radius: 17PX;
    overflow: hidden;
    color: #999;
    transition: all 0.3s;

    &.active {
      width: 400px;
    }

    .el-icon-search {
      padding: 8PX;
      font-size: 16PX;
    }

    .input {
      flex: 1;
      height: 100%;
      margin-left: 10px;
      border: none;
      outline: none;
      background: transparent;
    }
  }
</style>
