<template>
  <div class="flex column" style="height: 100%;">
    <v-title value="登录"/>
    <app-header/>
    <div class="page flex-1">
      <el-form class="form-wrapper" ref="form" :model="form">
        <el-form-item label="">
          <el-input v-model="form.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input type="password" v-model="form.password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item class="text-center">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class Login extends Vue {
    form = {
      email: '',
      password: ''
    }

    @Vue.autoLoading
    async handleSubmit() {
      await this.$authStore.login(this.form);
      const { redirect = '' } = this.$route.query;
      this.$router.replace(decodeURIComponent(redirect) || '/');
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(https://unsplash.it/1920/1080) no-repeat center center scroll;

    .form-wrapper {
      width: 300px;
    }
  }
</style>
