import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

const ACCESS_TOKEN_KEY = 'USER_DATA';

class AuthStore extends SimpleStore {
  $access_token = localStorage.getItem(ACCESS_TOKEN_KEY)
  user = {}

  set access_token(v) {
    this.$access_token = v;
    localStorage.setItem(ACCESS_TOKEN_KEY, v);
  }

  get access_token() {
    return this.$access_token;
  }

  get isLogin() {
    return !!this.access_token;
  }

  async fetchData() {
    const { data } = await this.fetching(fly.get('users/current'));
    this.user = data;
  }

  async checkFetchData() {
    if (this.access_token) {
      const { data } = await this.fetching(fly.get('users/current'));
      return this.user = data;
    }
    return false;
  }

  // 登录
  async login(params = {}) {
    const { data: { token, user } } = await fly.post('users/login', params);

    this.user = user;
    return this.access_token = token;
  }

  logout() {
    localStorage.setItem(ACCESS_TOKEN_KEY, '');
    this.access_token = '';
    this.user = {};
  }

  async update(body) {
    const { data } = await fly.put('/users/current', body);
    this.user = data;
  }
}

export const authStore = new AuthStore();
