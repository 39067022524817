export const isIOS = () => {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};

export const getIosVersion = () => {
  if (isIOS()) {
    const version = navigator.appVersion.match(/os (\d+)_(\d+)_?(\d+)?/i);
    return parseInt(version[1], 10);
  } else {
    return false;
  }
};
