<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { authorStore } from '@/stores';
  import _ from 'lodash';

  @Component
  class Title extends Vue {
    @Prop(String) value;

    authorStore = authorStore

    created() {
      this.listener = this.$watch(
        () => this.value || _.get(authorStore, 'siteTitle'),
        title => {
          if (title) {
            this.$setTitle(title);
          }
        },
        { immediate: true }
      );
    }

    beforeDestroy() {
      this.listener();
    }

    render() {
      return null;
    }
  }

  export default {
    install: Vue => Vue.component('v-title', Title)
  };
</script>
