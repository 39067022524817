<template>
  <div v-if="show">
    <router-link class="post-item-wrapper" :to="{ name: 'post.detail', params: { id: post.id } }">
      <div class="content-wrappe">
        <div class="title">{{ post.title }}</div>
        <div class="excerpt text-overflow-2">{{ post.summary }}</div>
      </div>
      <div class="footer-wrapper flex item-center content-between">
        <div class="flex item-center author-info">
          <div class="author flex item-center">
            <img :src="post.user.avatar" alt="" class="avatar">
            <div class="nickname">{{ post.user.nickname }}</div>
          </div>
          <div class="date">
            <i class="el-icon-time"></i>
            <span style="margin-left: 0.33333rem;">{{ time | timeFormat('YYYY-MM-DD') }}</span>
          </div>
          <div class="labels flex" style="text-transform: uppercase;" v-if="$get(post, 'labels.length')">
            <div class="item"><i class="el-icon-collection-tag"></i></div>
            <div
              class="item"
              style="margin-left: 0.41667rem;"
              v-for="item in post.labels"
              :key="item.id"
            >{{ item.name }}</div>
          </div>
        </div>
        <div class="icon-wrapper flex-end" v-if="showEditIcon">
          <i class="icon el-icon-edit" @click.stop.prevent="$router.push({ name: 'post.edit', params: { id: post.id } })"></i>
          <i class="icon el-icon-delete" @click.stop.prevent="handleDelete"></i>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { authStore } from '@/stores';

  @Component
  export default class PostCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) post

    authStore = authStore
    show = true

    get time() {
      return this.post.publishedAt || this.post.updatedAt;
    }

    get showEditIcon() {
      const authorId = _.get(this.post, 'user.id');
      return authorId && authorId === _.get(authStore.user, 'id');
    }

    async handleDelete() {
      await this.$confirm(`确定删除《${this.post.title}》?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.$fly.delete(`posts/${this.post.id}`);
      this.$message(`删除《${this.post.title}》成功！`);
      this.show = false;
    }
  }
</script>

<style lang="scss" scoped>
  .post-item-wrapper {
    display: block;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    color: #000;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #333;
    }

    .excerpt {
      margin: 10px 0;
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 14px;
      color: #666;
    }

    .footer-wrapper {
      font-size: 12px;
      color: #999;

      .author-info {
        margin-left: -8px;

        .nickname {
          font-size: 14px;
        }

        > div {
          position: relative;
          padding: 0 8px;

          &:not(:first-child)::after {
            position: absolute;
            top: 50%;
            left: 0;
            height: 12px;
            border-left: 1px solid #e1e1e1;
            transform: translateY(-50%);
            content: '';
          }
        }
      }

      .author {
        .avatar {
          width: 22px;
          height: 22px;
          margin-right: 5px;
          border: 1px solid #f1f1f1;
          border-radius: 50%;
        }
      }

      .icon-wrapper {
        font-size: 14px;

        .icon {
          margin-left: 8px;
        }
      }
    }
  }
</style>
