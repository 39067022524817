import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

class AuthorStore extends SimpleStore {
  avatar = ''
  createdAt = ''
  email = ''
  github = ''
  id = null
  introduction = ''
  nickname = ''
  slogan = ''
  updatedAt = ''
  siteTitle = ''

  async fetchData() {
    await this.mergeFetched(this.fetching(fly.get('author')));
  }
}

export const authorStore = new AuthorStore();
