<template>
  <div>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" ref="photoswipe" tabindex="-1" role="dialog" aria-hidden="true">
      <!-- Background of PhotoSwipe.
          It's a separate element as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">
        <!-- Container that holds slides.
              PhotoSwipe keeps only 3 of them in the DOM to save memory.
              Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">
          <div class="pswp__top-bar">
            <!--  Controls are self-explanatory. Order can be changed. -->

            <div class="pswp__counter"></div>

            <button
              class="pswp__button pswp__button--close"
              title="Close (Esc)"
            ></button>

            <button class="pswp__button pswp__button--share" title="Share"></button>

            <button
              class="pswp__button pswp__button--fs"
              title="Toggle fullscreen"
            ></button>

            <button
              class="pswp__button pswp__button--zoom"
              title="Zoom in/out"
            ></button>

            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader--active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button
            class="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          ></button>

          <button
            class="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          ></button>

          <div class="pswp__caption">
            <div class="pswp__caption__center"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';
  import PhotoSwipe from 'photoswipe';
  import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';
  import 'photoswipe/dist/photoswipe.css';
  import 'photoswipe/dist/default-skin/default-skin.css';

  @Component
  export default class PreviewImage extends Vue {
    beforeDestroy() {
      this.gallery && this.gallery.destroy();
    }

    /**
     * 预览图片
     *
     * @param  {Array} images  需要预览的图片数组，支持 Array[Object]，options.key 指定URL字段，默认: url
     * @param  {Object | number}      options 预览参数，number时，表示预览的当前index
     *
     * @return {Object}               new PhotoSwipe 实例
     */
    preview(images = [], options = {}) {
      images = [].concat(images);
      const defaultOptions = {
        key: 'url',
        shareEl: false,
        tapToClose: true,
        index: 0
      };
      options = typeof options === 'object' ? { ...defaultOptions, ...options } : { ...defaultOptions, index: options };
      this.images = images.map(item => {
        const image = { w: 10, h: 10 };
        image.src = typeof item === 'string' ? item : this.$get(item, options.key);
        return image;
      });
      this.gallery = new PhotoSwipe(this.$refs.photoswipe, PhotoSwipeUiDefault, this.images, _.omit(options, 'key'));
      this.initListens();
      this.gallery.init();
      this.images.map(image => this.loadImage(image));
      return this.gallery;
    }

    async loadImage(image) {
      const img = new Image();
      img.src = image.src;
      const { height: h, width: w } = await new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
      Object.assign(image, { h, w });
      const length = this.gallery.items.length;
      this.gallery.items.splice(0, length, ...this.images);
      // updates the content of slides
      this.gallery.updateSize(true);
    }

    initListens() {
      [
        'beforeChange',
        'afterChange',
        'imageLoadComplete',
        'resize',
        'gettingData',
        'mouseUsed',
        'initialZoomIn',
        'initialZoomInEnd',
        'initialZoomOut',
        'initialZoomOutEnd',
        'parseVerticalMargin',
        'close',
        'unbindEvents',
        'destroy',
        'updateScrollOffset',
        'preventDragEvent',
        'shareLinkClick',
      ].map(item => this.gallery.listen(item, (...ags) => {
        item === 'destroy' && (this.gallery = null);
        this.$emit(item, ...ags);
      }));
    }
  }
</script>

<style lang="scss" scoped>
</style>
