<template>
  <div class="side-menu-wrapper" v-show="value">
    <div class="mask" @click="showContent = false"></div>
    <transition name="slide-right" @after-leave="$emit('input', showContent)">
      <slot v-if="showContent"/>
    </transition>
  </div>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';

  @Component
  export default class SideMenu extends Vue {
    @Model('input', { type: Boolean, default: false }) value;

    showContent = false;

    @Watch('value', { immediate: true })
    valueChange(val, old) {
      if (val !== old) {
        this.showContent = this.value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .side-menu-wrapper {
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .mask {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.5);
    }
  }

  .slide-right-enter {
    transform: translateX(-100%);
  }

  .slide-right-enter-to {
    transform: translateX(0);
    transition: all 0.3s;
  }

  .slide-right-leave-active {
    transform: translateX(-100%);
    transition: all 0.3s;
  }
</style>
