<template>
  <div class="footer-component">
    <span class="item uppercase">{{ authorStore.siteTitle || authorStore.nicename }} © {{ year }}</span>
    <a class="item" :href="`mailto: ${authorStore.email}`">{{ authorStore.email }}</a>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authorStore } from '@/stores';

  @Component
  export default class Footer extends Vue {
    authorStore = authorStore
    year = (new Date).getFullYear()
  }
</script>

<style lang="scss" scoped>
  .footer-component {
    padding-top: 25px;
    padding-bottom: calc(25px + env(safe-area-inset-top));
    border-top: 1px solid #f1f1f1;
    text-align: center;

    > .item {
      margin: 0 5px;
    }

    .uppercase {
      text-transform: uppercase;
    }
  }
</style>
