<template>
  <div>
    <v-title/>
    <app-header/>
    <div class="page-404">
      <div class="hit-the-floor">404</div>
      <div class="sub-title">The Page Is Not Found</div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class Page404 extends Vue {}
</script>

<style lang="scss" scoped>
  body {
    background-color: #f1f1f1;
  }

  .page-404 {
    margin-top: 150px;
    text-align: center;
  }

  .hit-the-floor {
    font-weight: bold;
    font-size: 8em;
    font-family: Helvetica;
    text-shadow: 0 1px 0 #ccc,
      0 2px 0 #c9c9c9,
      0 3px 0 #bbb,
      0 4px 0 #b9b9b9,
      0 5px 0 #aaa,
      0 6px 1px rgba(0, 0, 0, .1),
      0 0 5px rgba(0, 0, 0, .1),
      0 1px 3px rgba(0, 0, 0, .3),
      0 3px 5px rgba(0, 0, 0, .2),
      0 5px 10px rgba(0, 0, 0, .25),
      0 10px 10px rgba(0, 0, 0, .2),
      0 20px 20px rgba(0, 0, 0, .15);
    color: #fff;

    @include media-sm-and-up {
      font-size: 180px;
    }
  }

  .sub-title {
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    color: #a2a2a2;

    @include media-sm-and-up {
      font-size: 32px;
    }
  }
</style>
