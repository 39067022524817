import { Collection } from './collection';
import { fly } from '@/utils';
import { MessageBox } from 'element-ui';

class CategoriesAndLabels extends Collection {
  fetch = () => fly.get(this.type)

  async createItem() {
    const { value } = await MessageBox.prompt('请输入名称', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    });
    if (!value) { return; }
    const { data } = await fly.post(this.type, { name: value });
    this.data.push(data);
  }

  async updateItem(id) {
    const item = this.findItemById(id);
    if (!item) {
      return;
    }
    const { value } = await MessageBox.prompt('请输入名称', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPlaceholder: item.name
    });
    if (!value) { return; }
    const { data } = await fly.put(`${this.type}/${item.id}`, { name: value });
    const index = this.data.findIndex(({ id }) => item.id === id);
    this.data.splice(index, 1, data);
  }

  async deleteItem(id) {
    await MessageBox.confirm('此操作将永久删除, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });
    await fly.delete(`${this.type}/${id}`);
    const index = this.data.findIndex(item => item.id === id);
    this.data.splice(index, 1);
  }
}

export const categoriesStore = CategoriesAndLabels.create({ type: 'categories' });
export const labelsStore = CategoriesAndLabels.create({ type: 'labels' });
