<template>
  <div
    class="flex column"
    :class="`page-name-${$route.name.replace(/\./g, '-')}`"
    style="height: 100%;"
  >
    <div style="flex: 1;" class="relative">
      <!-- <keep-alive> -->
      <router-view class="route-view" :key="routerViewKey" />
      <!-- </keep-alive> -->
    </div>
    <el-backtop
      :right="15"
      :bottom="25"
    >
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <app-footer/>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import qs from 'qs';
  import { ENTRY_URL } from '@/constants';
  import { authStore, authorStore } from '@/stores';

  @Component
  export default class App extends Vue {
    async created() {
      authStore.checkFetchData();
      authorStore.tryFetchData();
    }

    mounted() {
      window.sessionStorage.setItem(ENTRY_URL, window.location.href.split('#')[0]);
    }

    get routerViewKey() {
      const { path, query } = this.$route;
      return `${path}?${qs.stringify(query)}`;
    }
  }
</script>

<style lang="scss">
  html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    @include media-xs-only {
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  body {
    @include paddingBottomSafeArea;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    color: #000000;
  }

  .container {
    width: 100%;
    max-width: 720PX;
    padding: 0 20px;
    margin: 0 auto;
  }

  .relative {
    position: relative;
  }

  div {
    box-sizing: border-box;
  }

  img {
    transform: translate3d(0, 0, 0);
  }

  a {
    text-decoration: none;
    color: inherit;
  }
</style>
