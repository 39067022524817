<template>
  <el-dialog title="收货地址" :visible="show" @update:visible="$emit('update:show', $event)">
    <el-form ref="form" :model="form" label-width="5rem">
      <el-form-item
        label="标题"
        prop="title"
        :rules="{ required: true, message: '标题不能为空', trigger: 'blur' }"
      >
        <el-input placeholder="文章标题" v-model="form.title"/>
      </el-form-item>
      <el-form-item
        label="分类"
        prop="categoryId"
        :rules="{ required: true, message: '分类不能为空', trigger: 'blur' }"
      >
        <el-select v-model="form.categoryId" placeholder="请选择文章分类">
          <el-option
            v-for="item in categoriesStore.data"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签">
        <el-select v-model="form.labels" multiple placeholder="请选择文章标签">
          <el-option
            v-for="item in labelsStore.data"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="cover">
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :http-request="handleUploadCover"
        >
          <img v-if="form.cover" :src="form.cover" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-input placeholder="cover" v-model="form.cover"/>
      </el-form-item>
      <el-form-item label="发布">
        <el-switch v-model="form.published"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleCreatePost">确定</el-button>
        <el-button @click="$emit('update:show', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator';
  import { labelsStore, categoriesStore } from '@/stores';
  import { uploadFile } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class PostInfoForm extends Vue {
    @Model('input', { type: Object, default: () => ({
      title: '',
      categoryId: '',
      labels: [],
      cover: '',
      published: false,
    }) }) value
    @Prop(Boolean) show

    labelsStore = labelsStore
    categoriesStore = categoriesStore
    form = {
      title: '',
      categoryId: '',
      labels: [],
      cover: '',
      published: false,
    }

    @Watch('show', { immediate: true })
    @Watch('value', { deep: true, immediate: true })
    valueChange() {
      this.show && _.merge(this.form, _.cloneDeep(this.value));
    }

    handleCreatePost() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit', _.cloneDeep(this.form));
        }
      });
    }

    async handleUploadCover(e) {
      const cover = await uploadFile(e.file);
      this.form.cover = cover;
      this.$emit('input', _.cloneDeep(this.form));
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .avatar-uploader {
    .el-upload {
      position: relative;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
    }

    .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      width: 100px;
      height: 100px;
      font-size: 28px;
      line-height: 100px;
      text-align: center;
      color: #8c939d;
    }

    .avatar {
      display: block;
      width: 100px;
      height: 100px;
    }
  }
</style>
