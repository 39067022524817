import _ from 'lodash';
import qs from 'qs';
import { decoder } from '@/utils/decoder';
import { authStore } from '@/stores';
import router from '@/router';

export function onSend(request) {
  if (!request.headers['Content-Type']) {
    request.headers['Content-Type'] = 'application/json';
  }
  if (request.method === 'GET' && request.body) {
    request.params = qs.stringify(request.body, { arrayFormat: 'brackets' });
    delete request.body;
  }
  if (!request.headers['Authorization']) {
    request.headers['Authorization'] = authStore.access_token;
  }
  return request;
}

export function onSucceed(res) {
  res['isResponse'] = true;
  res.meta = {};
  _.forEach(res.headers, (v, k) => {
    v = res.headers[k] = [].concat(v)[0];
    if (/^x-/.test(k)) {
      const key = _.snakeCase(k.replace(/^x-/, ''));
      res.meta[key] = decoder(v);
    }
  });
  return res;
}

export function onError(err) {
  const { error_message, messages, error, code } = _.get(err, 'response.data', {});
  err.message = error_message || messages || error || err.message;
  err.api_code = code;

  if (err.status === 401) {
    authStore.logout();
    const { fullPath = '' } = router.app.$route;
    router.replace({ name: 'login', query: { redirect: encodeURIComponent(fullPath) } });
  }
  return err;
}
