import Vue from 'vue';
export { decodeQuery, encodeQuery } from './query';

import { fly } from './fly';
import { sleep } from './sleep';
import { setTitle } from './set-title';
import previewImage from './preview-image';

Vue.prototype.$fly = fly;
Vue.prototype.$sleep = sleep;
Vue.prototype.$setTitle = setTitle;
Vue.prototype.$previewImage = previewImage;


export {
  fly, sleep, setTitle
};

export { decoder } from './decoder';
export { countdown } from './countdown';
export { randomString } from './random';
export { formVerification } from './form-verification';
export { uploadFile } from './upload';
export { getSdk } from './wechat-jssdk';
export { autoLoading } from './prompt';
