<template>
  <div>
    <app-popup :value="show" @input="show = false">
      <div class="menu-wrapper">
      <el-menu
        router
        :default-active="activeIndex"
        @select="handleSelect"
      >
        <el-menu-item index="home" :route="{ name: 'home' }">
          <i class="el-icon-mobile"></i>
          <span>home</span>
        </el-menu-item>
        <el-submenu :index="menu.index" v-for="menu in menus" :key="menu.index">
          <div class="flex item-center content-between" slot="title">
            <div>
              <i :class="menu.icon"></i>
              <span>{{ menu.title }}</span>
            </div>
            <i v-if="isLogin" class="el-icon-plus" style="margin-right: 1.66667rem;" @click.stop.prevent="menu.store.createItem()"></i>
          </div>
          <el-menu-item
            :index="`${menu.index}-${item.id}`"
            v-for="item in menu.store.data"
            :key="item.id"
            :route="{ name: 'post.list', query: { [`${menu.index}_id`]: item.id } }"
          >
            <div class="flex content-between item-center">
              <span slot="title">{{ item.name }}</span>
              <div  @click.stop.prevent v-if="isLogin">
                <el-dropdown trigger="click" @command="handleCommand">
                  <i class="el-icon-setting"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{type: 'updateItem', menu, item}">编辑</el-dropdown-item>
                    <el-dropdown-item :command="{type: 'deleteItem', menu, item}">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="author" :route="{ name: 'author' }">
          <div class="flex content-between item-center">
            <div>
              <i class="el-icon-user"></i>
              <span slot="title">about</span>
            </div>
            <i class="el-icon-setting" v-if="isLogin" @click.stop.prevent="$router.push({ name: 'author.edit' })"></i>
          </div>
        </el-menu-item>
        <el-menu-item v-if="isLogin" index="post-new" :route="{ name: 'post.new' }">
          <i class="el-icon-document-add"></i>
          <span>写文章</span>
        </el-menu-item>
      </el-menu>
    </div>
    </app-popup>
    <i class="el-icon-s-unfold" @click="show = true"></i>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { labelsStore, categoriesStore, authStore } from '@/stores';

  @Component
  export default class SideMenu extends Vue {
    show = false
    categoriesStore = categoriesStore
    labelsStore = labelsStore

    postForm = {
      title: '',
      categoryId: '',
      labels: [],
      cover: '',
      published: false,
    }

    get menus() {
      return [
        {
          index: 'category',
          title: 'blog',
          store: this.categoriesStore,
          icon: 'el-icon-notebook-2',
        },
        {
          index: 'label',
          title: 'tags',
          store: this.labelsStore,
          icon: 'el-icon-collection-tag',
        }
      ];
    }

    get activeIndex() {
      const { name, query: { label_id, category_id } } = this.$route;
      if (name === 'home' || name === 'author') {
        return name;
      } else if (name !== 'post.list') {
        return '';
      } else if (category_id) {
        return `category-${category_id}`;
      } else if (label_id) {
        return `label-${label_id}`;
      }
      return '';
    }

    get isLogin() {
      return authStore.user.id;
    }

    created() {
      categoriesStore.tryFetchData();
      labelsStore.tryFetchData();
    }

    handleCommand(e) {
      const { type, menu: { store }, item: { id } } = e;
      store[type](id);
    }

    handleSelect(e) {
      if (e !== this.activeIndex) {
        this.show = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-icon-s-unfold {
    font-size: 26px;
    color: #666;
    cursor: pointer;
  }

  .menu-wrapper {
    width: 260px;
    height: 100%;
    text-transform: uppercase;
    background: #fff;

    > .el-menu {
      height: 100%;
      overflow: auto;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
</style>
