import PreviewImage from '@/components/preview-image';

export default function previewImage(images = [], index) {
  const instance = new PreviewImage().$mount();
  document.body.appendChild(instance.$el);
  instance.preview(images, index);
  instance.$on('destroy', () => {
    document.body.removeChild(instance.$el);
    instance.$destroy();
  });
}
