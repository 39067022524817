<template>
  <div>
    <v-title :value="authorStore.nickname"/>
    <app-header/>

    <div class="container">
      <img :src="authorStore.avatar" alt="" class="avatar">
      <div class="nickname">{{ authorStore.nickname }}</div>
      <div class="slogan" v-if="authorStore.slogan">{{ authorStore.slogan }}</div>
      <div class="introduction" v-if="authorStore.introduction">
        <markdown-editor only-read :value="authorStore.introduction"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authorStore } from '@/stores';

  @Component
  export default class Author extends Vue {
    authorStore = authorStore

    @Vue.autoLoading
    created() {
      return authorStore.tryFetchData();
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .header-component {
    border-bottom: 1px solid #f1f1f1;

    .el-icon-s-unfold {
      color: #666;
    }
  }

  .container {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    .nickname {
      margin: 20px 0 15px;
      font-size: 18px;
    }

    .avatar {
      width: 80px;
      height: 80px;
      border: 5px solid #f1f1f1;
      border-radius: 50%;
    }

    .slogan {
      margin-bottom: 50px;
    }

    .introduction {
      text-align: left;
    }
  }
</style>
