<template>
  <div class="flex column" style="height: 100%;">
    <v-title :value="post.title"/>
    <app-header style="margin-bottom: 0.83333rem;" hidden-search>
      <div class="el-icon-setting" @click="showPostForm = true"></div>
    </app-header>
    <div class="flex-auto flex container column">
      <div class="flex-auto flex column">
        <div class="flex-1">
          <markdown-editor @loaded="handleLoadedEditor" :value="post.draft" @input="($event) => handleAutoSave($event)"/>
        </div>
      </div>
      <div class="flex content-end" style="padding: 0.83333rem 0;">
        <el-button type="primary" @click="handlePublished">发表</el-button>
        <el-button @click="handleSave">保存</el-button>
      </div>
    </div>
    <post-info-form
      :show.sync="showPostForm"
      :value="postForm"
      @submit="handleConformPostInfo"
    />
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class PostEdit extends Vue {
    post = {}
    showPostForm = false

    postForm = {
      title: '',
      categoryId: '',
      labels: [],
      cover: '',
      published: false,
    }

    @Watch('post', { deep: true, immediate: true })
    postChange(val = {}) {
      const value = _.pick(val, Object.keys(this.postForm));
      const labels = _.map(val.labels, label => label.id);
      _.merge(this.postForm, { ...value, labels });
    }

    async created() {
      if (this.$route.params.id) {
        const { data } = await this.$fly.get(`posts/${this.$route.params.id}/current`);
        this.post = data;
      }
    }

    handleConformPostInfo(form) {
      this.postForm = form;
      this.showPostForm = false;
    }

    handleLoadedEditor(vditor) {
      this.$vditor = vditor;
    }

    // 自动保存
    handleAutoSave = _.throttle(async function (value) {
      const id = this.$route.params.id;
      if (id) {
        try {
          await this.$fly.put(`/posts/${id}`, { draft: value });
          this.$message.success({ message: '草稿保存成功', duration: 1000 });
        } catch (e) {
          this.$message.error('草稿保存失败');
        }
      }
    }, 10000, { trailing: false, leading: true })

    handlePublished() {
      this.postForm.published = true;
      this.handleSave(true);
    }

    @Vue.autoLoading
    async handleSave(navDetail) {
      const draft = this.$vditor.getValue();
      const body = {
        draft,
        ...this.postForm
      };
      if (body.published) {
        body.content = body.draft;
        body.summary = this.getSummary();
      }
      const id = this.$route.params.id;
      const { data } = await this.$fly[id ? 'put' : 'post'](id ? `/posts/${id}` : 'posts', body);
      this.$message.success({ message: '保存成功', duration: 1000 });
      // 新增文章，并且不发布时，保存重定向到文章编辑页面
      if (!id && !body.published) {
        this.$router.replace({ name: 'post.edit', params: { id: data.id } });
      } else if (navDetail === true) {
        // 发表后直接跳转到详情页
        this.$router.replace({ name: 'post.detail', params: { id: data.id } });
      }
    }

    // 获取文章摘要
    getSummary() {
      const div = document.createElement('div');
      div.innerHTML = this.$vditor.getHTML().replace('<p>[toc]</p>', '');
      const p = div.querySelector('p');
      return p ? p.innerText : (div.firstChild ? div.firstChild.innerText : '');
    }
  }
</script>

<style lang="scss" scoped>
  .el-icon-setting {
    font-size: 22px;
    color: #666;
  }
</style>
