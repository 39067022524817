import Vue from 'vue';
import VueRouter from 'vue-router';
import { decodeQuery, encodeQuery } from '@/utils';
import { MessageBox } from 'element-ui';
import _ from 'lodash';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: require('@/views/home').default
  },
  {
    path: '/posts',
    name: 'post.list',
    component: require('@/views/post-list').default
  },
  {
    path: '/posts/new',
    name: 'post.new',
    component: require('@/views/post-edit').default
  },
  {
    path: '/posts/:id',
    name: 'post.detail',
    component: require('@/views/post-detail').default
  },
  {
    path: '/posts/:id/edit',
    name: 'post.edit',
    component: require('@/views/post-edit').default
  },
  {
    path: '/author',
    name: 'author',
    component: require('@/views/author').default
  },
  {
    path: '/author/edit',
    name: 'author.edit',
    component: require('@/views/author-edit').default
  },
  {
    path: '/login',
    name: 'login',
    component: require('@/views/login').default
  },
  {
    path: '*',
    component: require('@/views/404').default,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  parseQuery(query) {
    return decodeQuery(query);
  },
  stringifyQuery(query) {
    return encodeQuery(query);
  },
});
const routeList = [];

router.beforeEach(async (to, from, next) => {
  const pickArr = ['name', 'query', 'params'];
  const fromJson = JSON.stringify(_.pick(from, pickArr));
  if (fromJson === JSON.stringify(_.pick(to, pickArr))) {
    return;
  }
  const { is_author } = from.query;
  if (is_author && !to.query.is_author) {
    const route = _.cloneDeep(_.pick(to, pickArr));
    is_author && (route.query.is_author = is_author);
    if (JSON.stringify(route) === fromJson) {
      return;
    }
    router.push(route);
    return;
  }

  // 页面跳转前关闭所有弹窗
  // eslint-disable-next-line
  try { MessageBox.close() } catch (e) { }

  let index = -1;
  for (let i = 0; i < routeList.length; i++) {
    if (routeList[i].name === to.name) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    // 如果存在路由列表，则把之后的路由都删掉
    routeList.splice(index + 1, routeList.length - index - 1);
  } else if (to.name !== 'login') {
    routeList.push({ 'name': to.name, 'path': to.fullPath });
  }
  to.meta.routeList = routeList;

  // if (_.get(to, 'meta.auth')) {
  //   if (authStore.isLogin) {
  //     await authStore.tryFetchData();
  //     return next();
  //   }
  //   return next({ name: 'login', query: { redirect: encodeURIComponent(to.fullPath) } });
  // }
  next();
});

export default router;
