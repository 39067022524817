<template>
  <div class="header-component">
    <div class="container">
      <div class="flex item-center content-between">
        <app-side-menu/>
        <div class="search-box" style="max-width: 75%;" v-if="!hiddenSearch">
          <app-search-input/>
        </div>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Hearder extends Vue {
    @Prop(Boolean) hiddenSearch
  }
</script>

<style lang="scss" scoped>
  .header-component {
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
  }
</style>
