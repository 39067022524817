import Vue from 'vue';
import { getSdk } from '@/utils';
import { authorStore } from '@/stores';
import _ from 'lodash';

Vue.mixin({
  created() {
    const routeName = _.get(this, '$route.name');
    if (routeName && routeName !== 'post.detail') {
      this.$shareInfo();
    }
  },
  methods: {
    /**
     * 设置微信分享信息
     *
     * @param  {Object} options 分享参数
     * @param  {Staging} options.title 分享标题
     * @param  {Staging} options.desc 分享描述
     * @param  {Staging} options.link 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
     * @param  {Staging} options.imgUrl 分享图标
     */
    $shareInfo: _.debounce(async function(options = {}) {
      if (!this.$wx) {
        this.$wx = await getSdk();
      }
      const defaultOpts = {
        link: options.link || window.location.href,
        imgUrl: options.imgUrl || authorStore.avatar,
      };
      this.$wx.onMenuShareTimeline(Object.assign({}, options, defaultOpts));
      this.$wx.onMenuShareAppMessage(Object.assign({}, options, defaultOpts));
    }, 200),
  }
});
