/* eslint-disable pathname/match-name */
import SparkMD5 from 'spark-md5';
import { fly } from './fly';
import { randomFileName } from './random';

const fileSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

// https://github.com/rails/rails/blob/master/activestorage/app/javascript/activestorage/file_checksum.js
class FileChecksum {
  static create(file, callback) {
    const instance = new FileChecksum(file);
    instance.create(callback);
  }

  constructor(file) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback) {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer;
    this.fileReader = new FileReader;
    this.fileReader.addEventListener('load', event => this.fileReaderDidLoad(event));
    this.fileReader.addEventListener('error', event => this.fileReaderDidError(event));
    this.readNextChunk();
  }

  fileReaderDidLoad(event) {
    this.md5Buffer.append(event.target.result);

    if (!this.readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);

      this.callback(null, base64digest);
    }
  }

  fileReaderDidError() {
    this.callback(`Error reading ${this.file.name}`);
  }

  readNextChunk() {
    if (this.chunkIndex < this.chunkCount || (this.chunkIndex === 0 && this.chunkCount === 0)) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = fileSlice.call(this.file, start, end);
      this.fileReader.readAsArrayBuffer(bytes);
      this.chunkIndex++;
      return true;
    } else {
      return false;
    }
  }
}

function createChecksum(file) {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        return reject(error);
      }
      resolve(checksum);
    });
  });
}

export async function uploadFile(file) {
  const body = {
    filename: file.name || randomFileName(''),
    contentType: file.type || 'application/octet-stream',
    byteSize: file.size,
    checksum: await createChecksum(file),
  };

  const { data: { url, headers } } = await fly.post('oss/direct_upload', body);
  // 1小时超时
  await fly.put(url, file.slice(), { headers: headers, timeout: 1 * 60 * 60 * 1000 });

  return url;
}

export function checkFileSize(file, size) {
  return file.size <= size * 1024 * 1024;
}

export function isImageFile(file) {
  return file.type.startsWith('image');
}

export function getImageInfo(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

export async function imageFileNeedCrop(file, cropperObj) {
  const { width, height } = await getImageInfo(file);
  // 可接受的误差
  const avaliableOffset = 2;
  const offset = Math.floor(Math.abs(cropperObj.width / cropperObj.height - width / height) * 100);
  return offset > avaliableOffset;
}
