<template>
  <div>
    <v-title/>
    <div class="header-wrapper">
      <div class="container">
        <div class="flex item-center content-between">
          <app-side-menu/>
          <app-search-input @search="handleSearch"/>
        </div>
      </div>
    </div>
    <div class="container">
      <app-post-list :store="store"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/stores';
  import { fly } from '@/utils';

  @Component
  export default class PostList extends Vue {
    keyword = ''

    store = Collection.create({
      fetch(params) {
        return fly.get(this.isAuthor ? 'posts/current' : 'posts', params);
      }
    })

    @Vue.autoLoading
    async created() {
      const {
        category_id,
        label_id,
        page = 1,
        keyword,
        is_author = false
      } = this.$route.query;
      this.store.isAuthor = is_author;
      const filter = {};
      keyword && (filter.title = { like: keyword });
      category_id && (filter.categoryId = { eq: category_id });
      label_id && (this.store.params.labelIds = [label_id]);
      this.store.params.filter = filter;
      return this.store.fetchData({ page });
    }

    handleSearch(keyword) {
      this.$router.push({
        name: 'post.list',
        query: { ...this.$route.query, keyword }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .header-wrapper {
    padding: 10px 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    /deep/ .el-icon-s-unfold {
      position: initial;
      margin-right: 20px;
      font-size: 22px;
      color: #666;
    }
  }
</style>
