<template>
  <div class="page">
    <v-title :value="post.title"/>
    <app-header/>
    <div class="relative" style="background: #000;">
      <img :src="post.cover || 'https://unsplash.it/750/750'" class="cover">
      <div class="container one-text" v-if="one">
        <p>{{ one.text }}</p>
        <div style="text-align: right;" v-if="one.textAuthors">—— {{ one.textAuthors }}</div>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <i v-if="showEditIcon" class="el-icon-edit" @click.stop.prevent="$router.push({ name: 'post.edit', params: { id: post.id } })"></i>
        <div class="header-content">
          <div class="title text-center">{{ post.title }}</div>
          <div class="sub flex item-center content-center">
            <div class="item"><i class="el-icon-time" style="margin-right: 0.41667rem;"></i>{{ post.publishedAt || post.createdAt | timeFormat }}</div>
            <div class="item">{{ length }} 字</div>
          </div>
        </div>
        <markdown-editor only-read :value="post.content"/>
        <div class="labels" v-if="$get(post, 'labels.length')">
          <span>TAGS: </span>
          <router-link
            class="label"
            :to="{ name: 'post.list', query: { label_id: item.id } }"
            v-for="item in post.labels"
            :key="item.id"
          >{{ item.name }}</router-link>
        </div>
      </div>
      <div class="author-info flex column item-center">
        <router-link :to="{ name: 'author' }">
          <img :src="post.user.avatar" class="avatar">
          <div class="author-name">{{ post.user.nickname }}</div>
        </router-link>
        <div class="author-slogan">{{ post.user.slogan }}</div>
      </div>
      <div class="relevant-list" v-if="relevantList">
        <app-post-card
          v-for="item in relevantList"
          :key="item.id"
          :post="item"
          type="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/stores';
  import _ from 'lodash';

  @Component
  export default class PostDetail extends Vue {
    post = {
      user: {}
    }
    relevantList = []

    one = null

    get length() {
      return _.get(this.post.content, 'length', 0);
    }

    get showEditIcon() {
      const authorId = _.get(this.post, 'user.id');
      return authorId && authorId === _.get(authStore.user, 'id');
    }

    @Vue.autoLoading
    async created() {
      const { is_author } = this.$route.query;
      let apiUrl = `posts/${this.$route.params.id}`;
      is_author && (apiUrl += '/current');
      this.fetchOneText();
      const { data } = await this.$fly.get(apiUrl);
      this.fetchRelevantList(data);
      this.post = data;
      this.$shareInfo({
        title: data.title,
        desc: data.summary,
        imgUrl: data.cover || data.user.avatar,
      });
    }

    async fetchRelevantList({ id, labels }) {
      if (!_.get(labels, 'length')) {
        return;
      }
      const { data } = await this.$fly.get('posts', {
        labelIds: _.map(labels, item => item.id),
        filter: {
          id: {
            ne: id
          }
        }
      });
      this.relevantList = data;
    }

    async fetchOneText() {
      const { data } = await this.$fly.get('one/last');
      this.one = data;
    }
  }
</script>

<style lang="scss" scoped>
  .cover {
    width: 100%;
    height: 370px;
    opacity: 0.7;
    object-fit: cover;
  }

  .one-text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: #fff;
    transform: translate(-50%, -50%);
  }

  /deep/ .header-component {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    border: none !important;

    .el-icon-s-unfold {
      color: #fff;
    }

    .input-wrapper {
      border-color: #fff;
      color: #fff;

      .input {
        color: #fff;

        &::placeholder {
          color: #e1e1e1;
        }
      }
    }
  }

  .header-content {
    padding: 20px 0;

    .title {
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 26px;
    }

    .sub {
      margin-left: -8px;
      font-size: 12px;
      color: #666;

      .item {
        position: relative;
        padding: 0 8px;

        &:not(:first-child)::before {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 1px;
          height: 12px;
          background: #f1f1f1;
          transform: translateY(-50%);
          content: '';
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 15px;
    padding-bottom: 40px;
    margin: auto;
    margin-top: -50px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

    .el-icon-edit {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
    }

    .labels {
      margin-top: 30px;
      font-size: 12px;
      text-transform: uppercase;
      color: #666;

      .label {
        padding: 0 10px;
        text-decoration: none;
        color: #666;

        &:not(:last-child) {
          border-right: 1px solid #f1f1f1;
        }
      }
    }
  }

  .author-info {
    padding: 50px 15px;
    text-align: center;

    .avatar {
      width: 100px;
      height: 100px;
      margin-bottom: 5px;
      border: 5px solid #eee;
      border-radius: 50%;
    }

    .author-name {
      margin-bottom: 15px;
      font-size: 20px;
    }

    .author-slogan {
      font-size: 14px;
      color: #333;
    }
  }
</style>
